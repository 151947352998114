import { useState } from "react"
import "../../styles/EmployeeItem.css"
import { useEmployees } from "../../hooks/useEmployees"
import { PulseLoader } from "react-spinners"

const EmployeeItem = ({ employee, refresh }) => {

    const { deleteEmployeeFromLicense, loading } = useEmployees()

    const [showOptions, setShowOptions] = useState(false)
    const handleShowOptions = () => setShowOptions(!showOptions)

    const deleteEmployee = () => {
        deleteEmployeeFromLicense(employee, refresh)
    }

    return (
        <div className="employee-item-container" onClick={handleShowOptions}>
            <label className="employee-item-label-role">{employee.role === "Admin" ? "Administrador" : "Conductor"}</label>
            <label className="employee-item-label-name">{employee.name} {employee.surname}</label>
            <label className="employee-item-label-username">{employee.username}</label>
            <label className="employee-item-label-contact-data">{employee.email}</label>
            {employee.phoneNumber ? (<label className="employee-item-label-contact-data">{employee.phoneNumber}</label>) : null}
            {!employee.email_verified ? (<label>Email sin verificar</label>) : null}

            {showOptions && employee.role != "Admin" ? (
                <button
                    className="employee-item-delete-button"
                    onClick={deleteEmployee}>

                    {
                        loading ? (
                            <PulseLoader
                                color="white"
                                loading={loading}
                                size={12} />
                        ) : "Eliminar de la licencia"
                    }
                    
                </button>
            ) : null}
        </div>
    )
}

export default EmployeeItem