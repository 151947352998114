import React, { useEffect } from "react";
import "../../styles/MyEmployees.css"
import { useEmployees } from "../../hooks/useEmployees";
import EmployeeItem from "../items/EmployeeItem";

function MyEmployees() {

    const { getLicenseData, deleteEmployeeFromLicense, license, employeesList, loading } = useEmployees()
    
    useEffect(() => {
        getLicenseData()
    }, [])

    useEffect(() => {
        console.log(employeesList)
    }, [employeesList])

    const refreshEmployeeList = () => {
        getLicenseData()
    }

    return (
        <div className="my-employees-container">
            <div>
                <h2>DATOS DE LA LICENCIA</h2>
            </div>

            {
                license ? (
                    <div className="my-employees-license-data-container">
                        <label className="my-employees-label-license-number">Licencia {license.licenseNumber}</label>
                        <label className="my-employees-label-license-name">{license.ownerName} {license.ownerSurname}</label>
                        <label className="my-employees-label-license-town">{license.town} - {license.province}</label>
                        <label className="my-employees-label-license-token">Token: {license.token}</label>
                    </div>
                ) : null
            }

            <div className="section-title">
                <h2>EMPLEADOS</h2>
            </div>

            <div className="my-employees-list-container">
                {
                    employeesList && employeesList.length > 0 ? employeesList.map((item, index) => (
                        <div key={index}>
                            <EmployeeItem employee={item} refresh={refreshEmployeeList} />
                        </div>
                    )) : (
                        <h2>No hay empleados</h2>
                    )
                }
            </div>

        </div>
    )
}

export default MyEmployees