import Cookies from "js-cookie";

export function loginUser(token) {
    Cookies.set("token", token, {
        //secure: true, // Enviar solo por HTTPS (habilitar cuando se use la api del servidor)
        sameSite: "Strict", // Protege contra ataques CSRF
        expires: 7 // Días antes de que exprire la cookie
    })
}

export function getToken() {
    const token = Cookies.get("token")
    return token || null
}

export function logoutUser() {
    Cookies.remove("token")
}