import React, { useState } from "react";
import "../styles/Login.css"
import "../styles/theme.css"
import { Link } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import Footer from "../components/common-components/Footer";
import Swal from "sweetalert2";
import { REGEX } from "../util/constants";
import { usePassword } from "../hooks/usePassword";
import { PulseLoader } from "react-spinners";

function Login() {

    const { handleLogin, loading, errors } = useLogin()
    const { handleRecoverPassword } = usePassword()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [saveLoginData, setSaveLoginData] = useState(false)
    const changePasswordVisibility = () => setShowPassword(!showPassword)
    const changeSaveLoginData = () => setSaveLoginData(!saveLoginData)

    const login = (e) => {
        e.preventDefault()

        handleLogin(username, password)
    }

    const recoverPassword = () => {
        Swal.fire({
            title: "Introduce tu correo electrónico para recuperar tu contraseña",
            icon: "question",
            html:
                '<input id="email" class="swal2-input" placeholder="Correo electrónico">',
            showCancelButton: true,
            confirmButtonText: "Enviar",
            cancelButtonText: "Cerrar",
            focusConfirm: false,
            preConfirm: () => {
                const enteredEmail = document.getElementById('email').value

                if (!enteredEmail) {
                    Swal.showValidationMessage("Introduce un correo electrónico")
                    return false
                }else if (!REGEX.EMAIL.test(enteredEmail)) {
                    Swal.showValidationMessage("Introduce un correo electrónico válido")
                    return false
                }

                return { enteredEmail }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const email = result.value.enteredEmail
                handleRecoverPassword(email)
            }
        })
    }

    return (
        <div className="login-container">

            <header className="login-header">
                <h3>TAXI TRACKER</h3>
            </header>

            <main className="login-main-container">
                <div className="login-sub-container">
                    <h1>Login</h1>
                    <form onSubmit={login} className="login-form-container">
                        <div className="login-input-container">
                            <label className="login-form-label">Nombre de usuario</label>
                            <input
                                className={`login-input ${errors.usernameError ? "error" : ""}`}
                                type="text"
                                placeholder="Usuario"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            {errors.usernameError ? <label className="login-form-error-label">{errors.usernameError}</label> : null}
                        </div>

                        <div className="login-input-container">
                            <div>
                                <label>Constraseña</label>
                            </div>
                            <div className="login-password-container">
                                <input
                                    className={`login-input ${errors.passwordError ? "error" : ""}`}
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Contraseña"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <FontAwesomeIcon
                                    className="login-icon-password"
                                    icon={showPassword ? faEyeSlash : faEye}
                                    color="black"
                                    onClick={changePasswordVisibility} />
                                    
                            </div>
                            {errors.passwordError ? <label className="login-form-error-label">{errors.passwordError}</label> : null}
                            <label onClick={recoverPassword} className="login-forgot-password-link">¿Olvidaste la contraseña?</label>
                        </div>

                        <div className="login-remember-user-container" onClick={changeSaveLoginData}>
                            <input
                                className="login-remember-user-checkbox"
                                type="checkbox"
                                checked={saveLoginData} />
                            <label className="login-remember-user-label">Recordar usuario</label>
                        </div>

                        <button type="submit" className="login-button">
                            {
                                loading ? (
                                    <PulseLoader
                                        color="var(--green2)"
                                        loading={loading}
                                        size={18} />
                                ) : "Iniciar sesión"
                            }
                        </button>
                    </form>

                    <label>¿No estás registrado? <Link to="/register" className="login-to-register-link">Regístrate</Link></label>
                </div>

            </main>

            <Footer />

        </div>
    )
}

export default Login