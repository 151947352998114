import { useState } from "react"
import "../../styles/AddService.css"
import { useService } from "../../hooks/useService"
import { PulseLoader } from "react-spinners"

function AddService({ fetchServices }) {

    const { handleAddService, serviceDayFinished, loading, errors } = useService()

    const [price, setPrice] = useState("")
    const [tips, setTips] = useState("")
    const [card, setCard] = useState(false)

    const changeCard = () => {
        setCard(!card)
    }

    const addService = (event) => {
        event.preventDefault()
        handleAddService(price, tips, card, fetchServices)
        setPrice("")
        setTips("")
        setCard(false)
    }

    return (
        <div className="add-service-container">
            <form className="add-service-form" onSubmit={addService}>
                <div className="add-service-input-container">
                    <label>Importe</label>
                    <input
                        className="add-service-input"
                        type="text"
                        placeholder="Importe"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)} />
                    {errors.priceError ? <p>{errors.priceError}</p> : null}
                </div>
                <div className="add-service-input-container">
                    <label>Propina</label>
                    <input 
                        className="add-service-input"
                        type="text"
                        placeholder="Propina"
                        value={tips}
                        onChange={(e) => setTips(e.target.value)} />
                    {errors.tipsError ? <p>{errors.tipsError}</p> : null}
                </div>
                <div className="add-service-checkbox-container" onClick={changeCard}>
                    <input
                        className="add-service-checkbox"
                        type="checkbox"
                        checked={card}/>
                    <label>Pago con tarjeta</label>
                </div>
                <button type="submit" className="add-service-button-save-service">
                    {
                        loading ? (
                            <PulseLoader
                                color="var(--green1)"
                                loading={loading}
                                size={14} />
                        ) : "Guardar"
                    }
                </button>
            </form>
        </div>
    )
}

export default AddService