import React, { useEffect, useState } from "react";
import "../../styles/Home.css"
import "../../styles/CurrentDay.css"
import "../../styles/theme.css"
import { useSelector } from "react-redux";
import { useServiceDay } from "../../hooks/useServiceDay";
import ServiceDayState from "../items/ServiceDayState";
import ServiceList from "../services/ServiceList";
import AddService from "../services/AddService";
import { useService } from "../../hooks/useService";
import { useServiceList } from "../../hooks/useServiceList";
import EditService from "../services/EditService";
import { PulseLoader } from "react-spinners";

function CurrentDay() {

    const serviceDay = useSelector((state) => state.serviceDay.data)
    const { checkServiceDay, initServiceDay, serviceDayInitiated, loading } = useServiceDay()
    const { fetchServices, services } = useServiceList()

    const [selectedService, setSelectedService] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(null)

    const serviceToEdit = (service, index) => {
        setSelectedService(service)
        setSelectedIndex(index)
    }

    const clearSelectedService = () => {
        setSelectedService(null)
        setSelectedIndex(null)
    }

    useEffect(() => {
        checkServiceDay()
        //fetchServices()
    }, [])

    return(
        <main className={`current-day-main-container ${loading ? "selected" : ""}`}>
            {
                loading ? (
                    <PulseLoader
                        color="var(--green1)"
                        loading={loading}
                        size={30} />

                ) : (
                    <div className="main-content">
                        <section className="current-day-content-1">
            
                            {
                                serviceDayInitiated ? (
                                    <ServiceList
                                        fetchServices={fetchServices}
                                        services={services}
                                        serviceToEdit={serviceToEdit}
                                        selectedService={selectedService}
                                    />
            
                                ) : (
                                    <button
                                        onClick={initServiceDay}
                                        className="current-day-button-new-day"
                                    >
                                            Nuevo día
                                    </button>
                                )
                            }
                        </section>
                        <section className="current-day-content-2">
                            <section className="current-day-add-service-content">
                                {serviceDayInitiated ? (
                                    <AddService fetchServices={fetchServices}/>
                                ) : null}
                            </section>
                            <section className="current-day-edit-service-content">
                                {serviceDayInitiated ? (
                                    <EditService
                                        fetchServices={fetchServices}
                                        serviceToEdit={selectedService}
                                        clearSelectedService={clearSelectedService} />
                                ) : null}
                                    
                            </section>
                        </section>
                    </div>
                )
            }
        </main>
    )
}

export default CurrentDay