import { useState } from "react"
import { REGEX } from "../util/constants"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import { errorResponse } from "../util/errors"
import Swal from "sweetalert2"
import { useNavigate } from "react-router-dom"

export const useRegister = () => {

    const navigation = useNavigate()

    const [loading, setLoading] = useState(false)
    const [errorsLicense, setErrorsLicense] = useState({
        nLicenseError: "",
        ownerNameError: "",
        ownerSurnameError: "",
        emailError: "",
        phoneNumberError: "",
        passwordError: "",
        confirmPasswordError: "",
        townError: "",
        provinceError: "",
    })

    const [errorsUser, setErrorsUser] = useState({
        nameError: "",
        surnameError: "",
        userNameError: "",
        emailError: "",
        phoneNumberError: "",
        passwordError: "",
        confirmPasswordError: "",
        licenseTokenError: ""
    })

    const [errorsEmployee, setErrorsEmployee] = useState({
        nameError: "",
        surnameError: "",
        userNameError: "",
        emailError: "",
        phoneNumberError: "",
        passwordError: "",
        confirmPasswordError: "",
    })

    const [errorsLocation, setErrorsLocation] = useState({
        townError: "",
        provinceError: ""
    })

    const handleRegisterUser = async (name, surname, username, email, phoneNumber, password, confirmPassword, licenseToken) => {
        
        if (!validateUserForm(name, surname, username, email, phoneNumber, password, confirmPassword)) return

        setLoading(true)

        const endPoint = ENDPOINT.REGISTER_USER

        let newUser = null
        if (licenseToken) {
            newUser = {
                name: name,
                surname: surname,
                userName: username,
                email: email,
                phoneNumber: phoneNumber ? phoneNumber : null,
                password: password,
                role: "Driver",
                license: {
                    token: licenseToken
                }
            }

        } else {
            newUser = {
                name: name,
                surname: surname,
                userName: username,
                email: email,
                phoneNumber: phoneNumber ? phoneNumber : null,
                password: password,
                role: "Driver"
            }
        }

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Town": null,
                    "Province": null
               },
               body: JSON.stringify(newUser)
            })

            if (response.ok) {
                Swal.fire({
                    title: "Usuario creado",
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })
                navigation('/login')
                
            } else {
                const data = await response.json()
                errorResponse(data, setErrorsUser)
            }

        } catch (error) {
            console.log("ERROR CREANDO EL USUARIO", error)

        } finally {
            setLoading(false)
        }
    }

    const handleRegisterUserWithoutLicense = async (name, surname, username, email, phoneNumber, password, confirmPassword, licenseToken, town, province) => {

        if (!validateUserForm(name, surname, username, email, phoneNumber, password, confirmPassword, licenseToken)) return
        if (!validateLocationForm(town, province)) return

        const endPoint = ENDPOINT.REGISTER_USER

        let newUser = null
        if (licenseToken) {
            newUser = {
                name: name,
                surname: surname,
                userName: username,
                email: email,
                phoneNumber: phoneNumber ? phoneNumber : null,
                password: password,
                role: "Driver",
                license: {
                    token: licenseToken
                }
            }

        } else {
            newUser = {
                name: name,
                surname: surname,
                userName: username,
                email: email,
                phoneNumber: phoneNumber ? phoneNumber : null,
                password: password,
                role: "Driver"
            }
        }

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Town": `${town}`,
                    "Province": `${province}`
                },
                body: JSON.stringify(newUser)
            })

            if (response.ok) {
                Swal.fire({
                    title: "Usuario registrado",
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })
                navigation("/login")

            } else {
                const data = await response.json()
                errorResponse(data, setErrorsUser)
            }

        } catch (error) {
            console.log("ERROR CREANDO EL USUARIO", error)

        } finally {
            setLoading(false)
        }

    }

    const handleRegisterLicense = async (nLicense, ownerName, ownerSurname, email, phoneNumber, password, confirmPassword, town, province) => {

        if (!validateLicenseForm(nLicense, ownerName, ownerSurname, email, phoneNumber, password, confirmPassword, town, province)) return

        setLoading(true)

        const endPoint = ENDPOINT.REGISTER_LICENSE

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    licenseNumber: nLicense,
                    ownerName: ownerName,
                    ownerSurname: ownerSurname,
                    email: email,
                    phoneNumber: phoneNumber ? phoneNumber : null,
                    password: password,
                    town: town,
                    province: province
                })
            })

            if (response.ok) {
                const data = await response.json()
                Swal.fire({
                    title: "Licencia registrada",
                    text: `Se ha creado un usuario administrador con la misma contraseña:\n\n 
                    - Nombre de usuario: ${data.userName}`,
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })
                navigation("/login")

            } else {
                const data = await response.json()
                errorResponse(data, setErrorsLicense)
            }

        } catch (error) {
            console.log("ERROR AL REGISTRAR LICENCIAAA", error)

        } finally {
            setLoading(false)
        }
    }

    const validateUserForm = (name, surname, username, email, phoneNumber, password, confirmPassword) => {

        let isValid = true
        setErrorsUser({
            nameError: "",
            surnameError: "",
            userNameError: "",
            emailError: "",
            phoneNumberError: "",
            passwordError: "",
            confirmPasswordError: "",
            licenseTokenError: ""
        })

        if (!name) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                nameError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(name)) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                nameError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!surname) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                surnameError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(surname)) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                surnameError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!username) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
            }))
            isValid = false
        } else if (!REGEX.USERNAME.test(username)) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                userNameError: "* Solo se permiten letras, guiones, guiones bajos y puntos"
            }))
            isValid = false
        }

        if (!email) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                emailError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.EMAIL.test(email)) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                emailError: "* Introduce un email válido"
            }))
            isValid = false
        }

        if (!phoneNumber) {

        } else if (!REGEX.PHONE_NUMER.test(phoneNumber)) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                phoneNumberError: "* Introduce un teléfono válido"
            }))
            isValid = false
        }

        if (!password) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                passwordError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.PASSWORD.test(password)) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        } else if (password.length < 4 || password.length > 20) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false
        }

        if (!confirmPassword) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                confirmPasswordError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NUMBER.test(confirmPassword)) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                confirmPasswordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        } else if (confirmPassword.length < 4 || confirmPassword.length > 20) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                confirmPasswordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false
        } else if (confirmPassword != password) {
            setErrorsUser((prevErrors) => ({
                ...prevErrors,
                passwordError: "* Las contraseñas no coinciden",
                confirmPasswordError: "* Las contraseñas no coinciden"
            }))
            isValid = false
        }

        return isValid
    }

    const validateLicenseForm = (nLicense, ownerName, ownerSurname, email, phoneNumber, password, confirmPassword, town, province) => {
        let isValid = true
        setErrorsLicense({
            nLicenseError: "",
            ownerNameError: "",
            ownerSurnameError: "",
            emailError: "",
            phoneNumberError: "",
            passwordError: "",
            confirmPasswordError: "",
            townError: "",
            provinceError: "",
        })

        if (!nLicense) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                nLicenseError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NUMBER.test(nLicense)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                nLicenseError: "* El valor debe ser numérico"
            }))
            isValid = false
        }

        if (!ownerName) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                ownerNameError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(ownerName)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                ownerNameError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!ownerSurname) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                ownerSurnameError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(ownerSurname)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                ownerSurnameError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!email) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                emailError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.EMAIL.test(email)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                emailError: "* Ingrese un email válido"
            }))
            isValid = false
        }
        
        if (!phoneNumber) {

        } else if (!REGEX.PHONE_NUMER.test(phoneNumber)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                phoneNumberError: "* Ingrese un teléfono válido"
            }))
            isValid = false
        }

        if (!password) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                passwordError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.PASSWORD.test(password)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        } else if (password.length < 4 || password.length > 20) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false
        }

        if (!confirmPassword) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                confirmPasswordError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.PASSWORD.test(confirmPassword)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                confirmPasswordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        } else if (password.length < 4 || password.length > 20) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                confirmPasswordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false
        } else if (confirmPassword != password) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                passwordError: "* Las contraseñas no coindicen",
                confirmPasswordError: "* Las contraseñas no coinciden"
            }))
            isValid = false
        }

        if (!town) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                townError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(town)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                townError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!province) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                provinceError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(province)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                provinceError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        return isValid
    }

    const validateLocationForm = (town, province) => {

        let isValid = true
        setErrorsLocation({
            townError: "",
            provinceError: ""
        })

        if (!town) {
            setErrorsLocation((prevErrors) => ({
                ...prevErrors,
                townError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(town)) {
            setErrorsLocation((prevErrors) => ({
                ...prevErrors,
                townError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!province) {
            setErrorsLocation((prevErrors) => ({
                ...prevErrors,
                provinceError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(province)) {
            setErrorsLocation((prevErrors) => ({
                ...prevErrors,
                provinceError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        return isValid
    }

    return {
        handleRegisterLicense,
        handleRegisterUser,
        handleRegisterUserWithoutLicense,
        loading,
        errorsLicense,
        errorsUser,
        errorsLocation
    }
}