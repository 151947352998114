import { useEffect, useState } from "react"
import "../../styles/AddService.css"
import "../../styles/theme.css"
import { useService } from "../../hooks/useService"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRectangleXmark, faTrash } from "@fortawesome/free-solid-svg-icons"
import { PulseLoader } from "react-spinners"

function EditService({ fetchServices, serviceToEdit, clearSelectedService }) {

    const { handleEditService, handleDeleteService, loading, errors } = useService()

    const [price, setPrice] = useState("")
    const [tips, setTips] = useState("")
    const [card, setCard] = useState(false)

    const changeCard = () => {
        setCard(!card)
    }

    const closeForm = () => {
        clearSelectedService()
    }

    useEffect(() => {
        if (serviceToEdit) {
            setPrice(serviceToEdit.price)
            setTips(serviceToEdit.tips ? serviceToEdit.tips : "")
            setCard(serviceToEdit.card)
        } else {
            setPrice("")
            setTips("")
            setCard(false)
        }
    }, [serviceToEdit])

    const editService = (event) => {
        event.preventDefault()
        handleEditService(serviceToEdit, price, tips, card, fetchServices, clearSelectedService)
    }

    const deleteService = (event) => {
        event.preventDefault()
        handleDeleteService(serviceToEdit, fetchServices, clearSelectedService)
    }

    return (
        <div className="add-service-container">
            {
                serviceToEdit ? (
                    <form className="add-service-form" onSubmit={editService}>
                        <div className="add-service-input-container">
                            <label>Importe</label>
                            <input
                                className="add-service-input"
                                type="text"
                                placeholder="Importe"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)} />
                            {errors.priceError ? <p>{errors.priceError}</p> : null}
                        </div>
                        <div className="add-service-input-container">
                            <label>Propina</label>
                            <input 
                                className="add-service-input"
                                type="text"
                                placeholder="Propina"
                                value={tips}
                                onChange={(e) => setTips(e.target.value)} />
                            {errors.tipsError ? <p>{errors.tipsError}</p> : null}
                        </div>
                        <div className="add-service-checkbox-container" onClick={changeCard}>
                            <input
                                className="add-service-checkbox"
                                type="checkbox"
                                checked={card} />
                            <label>Pago con tarjeta</label>
                        </div>
                        <div className="add-service-buttons-container ">
                            <button type="submit" className="add-service-button-save-service">
                                
                                {
                                    loading ? (
                                        <PulseLoader
                                            color="var(--green1)"
                                            loading={loading}
                                            size={12} />
                                    ) : "Guardar"
                                }
                            </button>
                            <button type="button" className="add-service-button-delete-service" onClick={deleteService}>
                                {
                                    loading ? (
                                        <PulseLoader
                                            color="white"
                                            loading={loading}
                                            size={12} />
                                    ) : "Eliminar"
                                }
                            </button>
                        </div>
                        <div className="add-service-close-edit-service-icon" onClick={closeForm}>
                            <FontAwesomeIcon
                                icon={faRectangleXmark}
                                color="var(--red7)" />
                        </div>
                    </form>
                ) : (
                    <h2>Selecciona un servicio para editar</h2>
                )
            }

            
        </div>
    )

}

export default EditService