import React, { useEffect, useState } from "react"
import "../../styles/Home.css"
import "../../styles/theme.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.module.css"
import { useConsult } from "../../hooks/useConsult"
import ServiceDayItem from "../items/ServiceDayItem"
import TotalServiceDays from "../totals/TotalServiceDays"
import ServiceList from "../services/ServiceList"
import { useServiceList } from "../../hooks/useServiceList"
import ServiceItem from "../items/ServiceItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faXmarkSquare } from "@fortawesome/free-solid-svg-icons"
import { useServiceDay } from "../../hooks/useServiceDay"
import { PulseLoader } from "react-spinners"

function Consult() {

    const { makeConsult, serviceDays, loading } = useConsult()
    const { handleDeleteServiceDay, deleting } = useServiceDay()

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [serviceDaysArray, setServiceDaysArray] = useState([])
    const [selectedServiceDay, setSelectedServiceDay] = useState(null)
    const [selectedServicesArray, setSelectedServicesArray] = useState([])

    useEffect(() => {
        if (startDate && endDate) {
            makeConsult(startDate, endDate)
        } else {
            setServiceDaysArray([])
        }
    }, [startDate, endDate])

    useEffect(() => {
        setServiceDaysArray(Object.values(serviceDays))
    }, [serviceDays])

    useEffect(() => {
        if (selectedServiceDay) {
            console.log("Selected Service Day", selectedServiceDay)
            setSelectedServicesArray(selectedServiceDay.services)
        }
    }, [selectedServiceDay])

    const clearSelectedServiceDay = () => {
        setSelectedServiceDay(null)
        setSelectedServicesArray([])
    }

    const deleteServiceDay = () => {
        if (selectedServiceDay) {
            handleDeleteServiceDay(selectedServiceDay.serviceDayData.id, refreshServiceDayList)
            clearSelectedServiceDay()
        }
    }

    const refreshServiceDayList = () => {
        makeConsult(startDate, endDate)
    }

    return (
        <main className="main-content">
            <section className="content-1">
                <div className="consults-date-selector-container">
                    <div className="consults-date-selector">
                        <label>Fecha inicio</label>
                        <DatePicker
                            className="consults-date-input"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            isClearable
                            showYearDropdown
                            scrollableMonthYearDropdown
                        />
                    </div>

                    <div className="consults-date-selector">
                        <label>Fecha fin</label>
                        <DatePicker
                            className="consults-date-input"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/AAAA"
                            isClearable
                            showYearDropdown
                            scrollableMonthYearDropdown
                        />
                    </div>
                </div>
                <div>
                    <TotalServiceDays serviceDays={serviceDaysArray}/>
                </div>
                <div className="consults-days-list-container">
                    {
                        loading ? (
                            <PulseLoader
                                color="var(--green1"
                                loading={loading}
                                size={30} />
                        ) : serviceDaysArray.length > 0 ? serviceDaysArray.map((item, index) => (
                            <div key={index} onClick={() => setSelectedServiceDay(item)}>
                                <ServiceDayItem
                                    serviceDay={item}
                                    selectedServiceDay={selectedServiceDay} />
                            </div>
                        )) : (<h3>Sin servicios</h3>)
                    }
                </div>
            </section>

            <section className="content-2">
                <div className="consults-service-list-container">
                    {
                        selectedServiceDay ? (
                            <div className="consults-options-service-day-container">
                                <button
                                    className="consults-delete-service-day-button"
                                    onClick={deleteServiceDay}>Eliminar</button>
                                <FontAwesomeIcon
                                    icon={faXmarkSquare}
                                    color="var(--red7)"
                                    onClick={clearSelectedServiceDay} />
                            </div>
                        ) : null
                    }
                    {
                        deleting ? (
                            <PulseLoader
                                color="var(--green1)"
                                loading={deleting}
                                size={20} />
                        ) : null
                    }
                    {
                        !selectedServiceDay ? 
                            <h3 className="consults-title-no-service-list">Selecciona un día para ver los servicios</h3>

                        : selectedServicesArray && selectedServicesArray.length > 0
                            ? selectedServicesArray.map((item, index) => (
                                <div key={index}>
                                    <ServiceItem
                                        service={item}
                                        index={index}
                                        selectedService={null}/>
                                </div>))
                            : (<h3 className="consults-title-no-service-list">Sin servicios</h3>)
                    }
                </div>
            </section>

        </main>
    )
}

export default Consult