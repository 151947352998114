import React, { useState } from "react";
import "../../styles/Forms.css"
import "../../styles/theme.css"
import Swal from "sweetalert2";
import { useRegister } from "../../hooks/useRegister";
import { REGEX } from "../../util/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { PulseLoader } from "react-spinners";

function FormUser() {

    const { handleRegisterUser, handleRegisterUserWithoutLicense, errorsUser, errorsLocation, loading} = useRegister()

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [licenseToken, setLicenseToken] = useState("")

    const [town, setTown] = useState("")
    const [province, setProvince] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const changePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const changeConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const onRegister = (event) => {
        event.preventDefault()
        if (!licenseToken) {
            Swal.fire({
                title: "Creación de usuario",
                html:
                '<p>Como el usuario no tiene un token de licencia, ingrese la población y la provincia:</p>' +
                '<input id="inputTown" class="swal2-input" placeholder="Localidad">' +
                '<input id="inputProvince" class="swal2-input" placeholder="Provincia">',
                icon: "question",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
                focusConfirm: false,
                showCancelButton: true,
                preConfirm: () => {
                    const enteredTown = document.getElementById('inputTown').value
                    const enteredProvince = document.getElementById('inputProvince').value
    
                    if (!enteredTown || !enteredProvince) {
                        Swal.showValidationMessage("Es necesario introducir localidad y provincia para continuar")
                        return false
                    } else if (!REGEX.NORMALIZED_CHARACTERS.test(enteredTown) || !REGEX.NORMALIZED_CHARACTERS.test(enteredProvince)) {
                        Swal.showValidationMessage("No están permitidos números o caracteres especiales")
                        return false
                    }
    
                    return { enteredTown, enteredProvince }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    setTown(result.value.enteredTown)
                    setProvince(result.value.enteredProvince)
    
                    handleRegisterUserWithoutLicense(name, surname, username, email, phoneNumber, password, confirmPassword, licenseToken, result.value.enteredTown, result.value.enteredProvince)
                }
            })

        } else handleRegisterUser(name, surname, username, email, phoneNumber, password, confirmPassword, licenseToken)          
    }
    
    return (

        <form className="form-user-container" onSubmit={onRegister}>
            
            <div className="form-user-first-section-container">
                <div className="form-user-single-data-container">
                    <label>Nombre</label>
                    <input
                        className={`form-user-register-input ${errorsUser.nameError ? "error" : ""}`}
                        type="text"
                        placeholder="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    {errorsUser.nameError ? <label className="form-user-error-label">{errorsUser.nameError}</label> : null}
                </div>
                <div className="form-user-single-data-container">
                    <label className="form-user-register-label">Apellidos</label>
                    <input
                        className={`form-user-register-input ${errorsUser.surnameError ? "error" : ""}`}
                        type="text"
                        placeholder="Apellidos"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                    {errorsUser.surnameError ? <label className="form-user-error-label">{errorsUser.surnameError}</label> : null}
                </div>
            </div>
            
            <div className="form-user-section-container">
                <div className="form-user-single-data-container">
                    <label className="form-user-register-label">Nombre de usuario</label>
                    <input
                        className={`form-user-register-input ${errorsUser.userNameError ? "error" : ""}`}
                        type="text"
                        placeholder="Nombre de usuario"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    {errorsUser.userNameError ? <label className="form-user-error-label">{errorsUser.userNameError}</label> : null}
                </div>
                <div className="form-user-single-data-container">
                    <label className="form-user-register-label">Email</label>
                    <input
                        className={`form-user-register-input ${errorsUser.emailError ? "error" : ""}`}
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errorsUser.emailError ? <label className="form-user-error-label">{errorsUser.emailError}</label> : null}
                </div>
            </div>
            
            <div className="form-user-section-container">
                <div className="form-user-single-data-container">
                    <label className="form-user-register-label">Teléfono (opcional)</label>
                    <input
                        className={`form-user-register-input ${errorsUser.phoneNumberError ? "error" : ""}`}
                        type="text"
                        placeholder="Teléfono"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errorsUser.phoneNumberError ? <label className="form-user-error-label">{errorsUser.phoneNumberError}</label> : null}
                </div>
                <div className="form-user-single-data-container">
                    <label className="form-user-register-label">Token (opcional)</label>
                    <input
                        className={`form-user-register-input ${errorsUser.nameError ? "error" : ""}`}
                        type="text"
                        placeholder="Token"
                        value={licenseToken}
                        onChange={(e) => setLicenseToken(e.target.value)}
                    />
                    {errorsUser.licenseTokenError ? <label className="form-user-error-label">{errorsUser.licenseTokenError}</label> : null}
                </div>
            </div>
            
            <div className="form-user-section-container">
                <div className="form-user-single-data-container">
                    <label className="form-user-register-label">Contraseña</label>
                    <div className="form-user-password-input-container">
                        <input
                            className={`form-user-register-input ${errorsUser.passwordError ? "error" : ""}`}
                            type={showPassword ? "text" : "password"}
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FontAwesomeIcon
                            className="form-user-password-icon"
                            icon={showPassword ? faEyeSlash : faEye}
                            color="black"
                            onClick={changePasswordVisibility} />
                    </div>
                    {errorsUser.passwordError ? <label className="form-user-error-label">{errorsUser.passwordError}</label> : null}
                </div>
                <div className="form-user-single-data-container">
                    <label className="form-user-register-label">Confirmar contraseña</label>
                    <div className="form-user-password-input-container">
                        <input
                            className={`form-user-register-input ${errorsUser.confirmPasswordError ? "error" : ""}`}
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirmar contraseña"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <FontAwesomeIcon
                            className="form-user-password-icon"
                            icon={showConfirmPassword ? faEyeSlash : faEye}
                            color="black"
                            onClick={changeConfirmPasswordVisibility} />
                    </div>
                    {errorsUser.confirmPasswordError ? <label className="form-user-error-label">{errorsUser.confirmPasswordError}</label> : null}
                </div>
            </div>

            <div className="form-user-register-button-container">
                <button className="form-user-register-button" type="submit">
                    {
                        loading ? (
                            <PulseLoader
                                color="var(--green2)"
                                loading={loading}
                                size={18} />
                        ) : "Registrar"
                    }
                </button>
            </div>

        </form>
    )
}

export default FormUser