import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice"
import serviceDayReducer from "./serviceDaySlice"

const store = configureStore({
    reducer: {
        user: userReducer,
        serviceDay: serviceDayReducer
    }
})

export default store