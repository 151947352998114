import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import { clearServiceDay, setServiceDay } from "../redux/serviceDaySlice.js"
import Swal from "sweetalert2"
import { getCurrentDate, getCurrentTime } from "../util/dates.js"

export const useServiceDay = () => {
    const dispatch = useDispatch()
    const serviceDay = useSelector((state) => state.serviceDay.data)

    const [serviceDayInitiated, setServiceDayInitiated] = useState(false)
    const [serviceDayFinished, setServiceDayFinished] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const checkServiceDay = async () => {
        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint1 = ENDPOINT.CHECK_SERVICE_DAY_CURRENT

        try {
            const response1 = await fetchWithTimeout(endPoint1, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response1.ok) {
                const data1 = await response1.json()
                dispatch(setServiceDay(data1))
                setServiceDayInitiated(true)

                if (data1.endDate) {
                    setServiceDayFinished(true)
                }

            } else {
                const endPoint2 = ENDPOINT.CHECK_SERVICE_DAY_PREVIOUS

                const response2 = await fetchWithTimeout(endPoint2, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                })

                if (response2.ok) {
                    const data2 = await response2.json()

                    if (data2 != null && data2.endDate === null) {
                        dispatch(setServiceDay(data2))
                        setServiceDayInitiated(true)
                    }
                }
            }

        } catch {
            Swal.fire({
                title: "Error de conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })

        } finally {
            setLoading(false)
        }
    }

    const initServiceDay = async () => {
        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.INIT_SERVICE_DAY

        const newServiceDay = {
            startDate: getCurrentDate(),
            startHour: getCurrentTime()
        }

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(newServiceDay)
            })

            if (response.ok) {
                const data = await response.json()
                dispatch(setServiceDay(data))
                setServiceDayInitiated(true)

            } else {
                Swal.fire({
                    title: "Día no iniciado",
                    text: "La respuesta del servidor al iniciar el día no ha sido correcta",
                    icon: "error",
                    confirmButtonText: "Cerrar"
                })
            }
        } catch (error) {
            Swal.fire({
                title: "Error al iniciar el día",
                text: "Error en la conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        } finally {
            setLoading(false)
        }
    }

    const handleFinishServiceDay = async () => {
        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.FINISH_SERVICE_DAY + `${serviceDay.id}`

        const updatedServiceDay = {
            id: serviceDay.id,
            user: serviceDay.user,
            startDate: serviceDay.startDate,
            startHour: serviceDay.startHour,
            endDate: getCurrentDate(),
            endHour: getCurrentTime()
        }

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(updatedServiceDay)
            })

            if (response.ok) {
                dispatch(setServiceDay(updatedServiceDay))
                setServiceDayFinished(true)
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Día de servicio no finalizado",
                    icon: "error",
                    confirmButtonText: "Cerrar"
                })
            }

        } catch (error) {
            Swal.fire({
                title: "Error al finalizar el día",
                text: "Error en la conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }
    }

    const handleReOpenServiceDay = async () => {
        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.OPEN_SERVICE_DAY + `${serviceDay.id}`

        const updatedServiceDay = {
            id: serviceDay.id,
            user: serviceDay.user,
            startDate: serviceDay.startDate,
            startHour: serviceDay.startHour,
            endDate: null,
            endHour: null
        }

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(updatedServiceDay)
            })

            if (response.ok) {
                dispatch(setServiceDay(updatedServiceDay))
                setServiceDayFinished(false)
            }

        } catch (error) {
            Swal.fire({
                title: "Error al reabrir el día",
                text: "Error en la conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }
    }

    const handleDeleteServiceDay = async (id, refresh) => {
        const token = getToken()
        if (!token) return

        setDeleting(true)

        const endPoint = ENDPOINT.DELETE_SERVICE_DAY + `${id}`
        
        try {
            
            const response = await fetchWithTimeout(endPoint, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                console.log("Día eliminado")
                refresh()
                //dispatch(clearServiceDay())
            } else {
                console.log("Día no eliminado")
            }

        } catch (error) {
            Swal.fire({
                title: "Error al eliminar el día",
                text: "Error en la conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        } finally {
            setDeleting(false)
        }

    }

    return {
        checkServiceDay,
        initServiceDay,
        handleFinishServiceDay,
        handleReOpenServiceDay,
        handleDeleteServiceDay,
        serviceDayInitiated,
        serviceDayFinished,
        loading,
        deleting
    }
}