import "../../styles/Footer.css"
import AppStore from "../../images/app-store.png"
import PlayStore from "../../images/google-play.png"
import Instagram from "../../images/instagram-icon.png"
import Github from "../../images/github-icon.png"
import Linkedin from "../../images/linkedin-icon.png"

function Footer() {
    return (
        <footer className="footer-container">

            <div className="footer-sub-container">
                <div className="footer-download-container">
                    <p className="footer-sub-container-title">Descarga la app</p>
                    <div className="footer-download-images-container">
                        <img
                            className="footer-img"
                            src={AppStore}
                            alt="AppStore" />
                        <img
                            className="footer-img"
                            src={PlayStore}
                            alt="PlayStore" />
                    </div>
                </div>
                <div className="footer-contact-container">
                    <p className="footer-sub-container-title">Sobre nosotros</p>
                    <label>¿Quiénes somos?</label>
                </div>
                <div className="footer-contact-container">
                    <p className="footer-sub-container-title">Contáctanos</p>
                    <label>app.taxi.tracker@gmail.com</label>

                    <div className="footer-icon-container">
                        <img
                            className="footer-icon"
                            src={Instagram}
                            alt="Instagram" />
                        <a href="https://github.com/mAnuelAA-96" target="_blank" rel="noopener noreferrer">
                            <img
                                className="footer-icon"
                                src={Github}
                                alt="Github" />
                        </a>
                        <a href="https://www.linkedin.com/in/m-flores-vilchez/" target="_blank" rel="noopener noreferrer">
                            <img
                                className="footer-icon"
                                src={Linkedin}
                                alt="Linkedin" />
                        </a>
                    </div>

                </div>
            </div>

            <label className="footer-label">© 2024 Taxi Tracker. Todos los derechos reservados.</label>
        </footer>
    )
}

export default Footer