import { useState } from "react"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { REGEX } from "../util/constants"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import Swal from "sweetalert2"
import { errorResponse } from "../util/errors"
import { useNavigate } from "react-router-dom"

export const usePassword = () => {

    const navigation = useNavigate()

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        passwordError: "",
        newPasswordError: "",
        confirmNewPasswordError: ""
    })

    const handleChangePassword = async (currentPassword, newPassword, confirmNewPassword) => {
        if (!validateForm(currentPassword, newPassword, confirmNewPassword)) return

        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.CHANGE_PASSWORD

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    oldPassword: currentPassword,
                    newPassword: newPassword
                })
            })

            if (response.ok) {
                Swal.fire({
                    title: "Contraseña modificada con éxito",
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })
                navigation(-1)

            } else {
                const data = await response.json()
                console.log(data)
                errorResponse(data, setErrors)
            }

        } catch (error) {
            Swal.fire({
                title: "Error de conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        } finally {
            setLoading(false)
        }
    }

    const handleRecoverPassword = async (email) => {
        setLoading(true)

        console.log(email)

        const endPoint = ENDPOINT.RECOVER_PASSWORD

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "email": `${email}`
                }
            })

            if (response.ok) {
                Swal.fire({
                    title: "Se ha enviado un email para reestablecer la contraseña",
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })

            } else {
                const data = response.json()
                errorResponse(data, null)
            }

        } catch (error) {
            Swal.fire({
                title: "Error de conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })

        } finally {
            setLoading(false)
        }


    }

    const validateForm = (currentPassword, newPassword, confirmNewPassword) => {
        let isValid = true
        setErrors({
            passwordError: "",
            newPasswordError: "",
            confirmNewPasswordError:""
        })

        if (!currentPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.PASSWORD.test(currentPassword)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        } else if (currentPassword.length < 4 || currentPassword.length > 20) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false
        }

        if (!newPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPasswordError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.PASSWORD.test(newPassword)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPasswordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        } else if (newPassword.length < 4 || newPassword.length > 20) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPasswordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false
        }

        if (!confirmNewPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmNewPasswordError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.PASSWORD.test(confirmNewPassword)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmNewPasswordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        } else if (confirmNewPassword.length < 4 || confirmNewPassword.length > 20) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                confirmNewPasswordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false
        } else if (newPassword != confirmNewPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                newPasswordError: "* Las contraseñas no coinciden",
                confirmNewPasswordError: "* Las contraseñas no coinciden"
            }))
            isValid = false
        }

        return isValid
    }

    return {
        handleChangePassword,
        handleRecoverPassword,
        errors,
        loading
    }
}