import React, { useState } from "react";
import "../styles/Register.css"
import FormLicense from "../components/forms/FormLicense.jsx";
import FormUser from "../components/forms/FormUser.jsx";
import Footer from "../components/common-components/Footer.jsx"

function Register() {

    const [selected, setSelected] = useState("l")

    return (
        <div className="register-container">
            <header className="register-header">
                <h3>TAXI TRACKER</h3>
            </header>

            <main className="register-main-container">

                {/* SELECTOR DE FORMULARIO */}
                <div className="register-form-selector-container">
                    <label
                        className={`register-selector-form ${selected === "l" ? "selected" : ""}`}
                        onClick={() => setSelected("l")}
                    >
                        Licencia
                    </label>
                    <label
                        className={`register-selector-form ${selected === "u" ? "selected" : ""}`}
                        onClick={() => setSelected("u")}
                    >
                        Usuario
                    </label>
                </div>


                {/* FORMULARIOS */}

                {selected === "l" ? (
                    <FormLicense/>
                ) : (
                    <FormUser/>
                )}
                   

            </main>

            <Footer />


        </div>
    )
}

export default Register