import React, { useEffect, useState } from "react";
import "../../styles/TotalServiceDays.css"

function TotalServiceDays({ serviceDays }) {

    const [total, setTotal] = useState(0.0)
    const [card, setCard] = useState(0.0)
    const [tips, setTips] = useState(0.0)

    const setSum = () => {
        let totalSum = 0.0
        let cardSum = 0.0
        let tipsSum = 0.0

        if (Array.isArray(serviceDays) && serviceDays.length > 0) {
            serviceDays.forEach(serviceDay => {
                if (Array.isArray(serviceDay.services) && serviceDay.services.length > 0) {
                    serviceDay.services.forEach(service => {
                        totalSum += service.price
                        tipsSum += service.tips || 0

                        if (service.card) {
                            cardSum += service.price
                        }
                    })
                }
            })
        }

        setTotal(totalSum)
        setCard(cardSum)
        setTips(tipsSum)
    }

    useEffect(() => {
        setSum()
    }, [serviceDays])

    return (
        <div className="total-days-container">
            <div className="single-total-container">
                <label>Total</label>
                <label className="label-price">{total.toFixed(2)} €</label>
            </div>
            <div className="single-total-container">
                <label>Tarjeta</label>
                <label className="label-price">{card.toFixed(2)} €</label>
            </div>
            <div className="single-total-container">
                <label>Propina</label>
                <label className="label-price">{tips.toFixed(2)} €</label>
            </div>
        </div>
    )
}

export default TotalServiceDays