import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Welcome from './screens/Welcome';
import Login from './screens/Login';
import Register from './screens/Register';
import Home from './screens/Home';
import Configuration from './components/home/Configuration';
import Profile from './components/home/Profile';
import ChangePassword from './components/home/ChangePassword';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Welcome />} />
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='/home' element={<Home />} />
      <Route path='/configuration' element={<Configuration />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/change-password' element={<ChangePassword />} />
    </Routes>
  )
}

export default App;
