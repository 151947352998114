import { useState } from "react"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import Swal from "sweetalert2"

export const useOnline = () => {

    const [loading, setLoading] = useState(false)
    const [onlineList, setOnlineList] = useState([])

    const getUsersOnlineMyZone = async () => {
        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.GET_USERS_ONLINE_MY_ZONE

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                const data = await response.json()
                setOnlineList(data)
            }

        } catch (error) {
            Swal.fire({
                title: "Error de conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        } finally {
            setLoading(false)
        }
    }

    return {
        getUsersOnlineMyZone,
        onlineList,
        loading
    }
}