import { useEffect } from "react"
import { useServiceList } from "../../hooks/useServiceList"
import ServiceDayState from "../items/ServiceDayState"
import ServiceItem from "../items/ServiceItem"
import "../../styles/ServiceList.css"
import TotalServiceDays from "../totals/TotalServiceDays"
import TotalServices from "./TotalServices"

function ServiceList({ fetchServices, services, serviceToEdit, selectedService }) {

    useEffect(() => {
        fetchServices()
    }, [])

    return (
        <div>
            <ServiceDayState />
            <TotalServices services={services} />
            <div className="service-list-container">
                {
                    services && services.length > 0 ? services.map((item, index) => (
                        <div key={index} onClick={() => serviceToEdit(item, index)}>
                            <ServiceItem
                                service={item}
                                index={index}
                                selectedService={selectedService} />
                        </div>
                    )) : (<h3>Sin servicios</h3>)
                }
            </div>
        </div>
    )

}

export default ServiceList