import { useNavigate } from "react-router-dom"
import { getToken, loginUser, logoutUser } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import { useDispatch, useSelector } from "react-redux"
import { setUser, clearUser } from "../redux/userSlice"
import Swal from "sweetalert2"
import { useState } from "react"
import { REGEX } from "../util/constants"

export const useUser = () => {

    const navigation = useNavigate()
    const dispatch = useDispatch()
    const userRedux = useSelector((state) => state.user.profile)

    const [loading, setLoading] = useState(false)
    const [user, setUserLogged] = useState(null)
    const [userModified, setUserModified] = useState(null)
    const [errors, setErrors] = useState({
        nameError: "",
        surnameError: "",
        userNameError: "",
        emailError: "",
        phoneNumberError: ""
    })

    const handleGetUser = async () => {
        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.GET_USER

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                const data = await response.json()
                setUserLogged(data)
            }

        } catch (error) {
            Swal.fire({
                title: "Error de conexión con el servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        } finally {
            setLoading(false)
        }

    }

    const handleUpdateUser = async (name, surname, username, email, phoneNumber) => {

        if (!validateEditUserForm(name, surname, username, email, phoneNumber)) return

        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.EDIT_USER

        const newUser = {
            name: name,
            surname: surname,
            userName: username,
            email: email,
            phoneNumber: phoneNumber ? phoneNumber : null
        }

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(newUser)
            })

            if (response.ok) {
                const data = await response.json()
                loginUser(data.jwt)

                const userData = {
                    name: data.name,
                    surname: data.surname,
                    username: data.userName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    license: userRedux.license,
                    role: userRedux.role
                }

                dispatch(setUser(userData))
                setUserModified(!userModified)
                Swal.fire({
                    title: "Datos de usuario modificados",
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })

            }

        } catch (error) {
            Swal.fire({
                title: "Error en la respuesta del servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })

        } finally {
            setLoading(false)
        }
    }

    const handleDeleteUser = async () => {
        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.DELETE_USER

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                navigation("/login")
                dispatch(clearUser())
                logoutUser()
                Swal.fire({
                    title: "Usuario eliminado",
                    text: "Usuario eliminado correctamente",
                    icon: "success",
                    confirmButtonText: "Cerrar"
                })
            }

        } catch (error) {
            Swal.fire({
                title: "Error en la respuesta del servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }
    }

    const validateEditUserForm = (name, surname, username, email, phoneNumber) => {
        let isValid = true
        setErrors({
            nameError: "",
            surnameError: "",
            userNameError: "",
            emailError: "",
            phoneNumberError: ""
        })

        if (!name) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                nameError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(name)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                nameError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!surname) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                surnameError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.NORMALIZED_CHARACTERS.test(surname)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                surnameError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!username) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                userNameError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.USERNAME.test(username)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                userNameError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!email) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                emailError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.EMAIL.test(email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                emailError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        if (!phoneNumber) {

        } else if (!REGEX.PHONE_NUMER.test(phoneNumber)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                phoneNumberError: "* No están permitidos caracteres especiales"
            }))
            isValid = false
        }

        return isValid
    }

    return {
        handleGetUser,
        handleUpdateUser,
        handleDeleteUser,
        user,
        userModified,
        loading,
        errors
    }
}