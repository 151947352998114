import "../../styles/Online.css"
import { useSelector } from "react-redux"
import { useOnline } from "../../hooks/useOnline"
import { useEffect, useState } from "react"
import OnlineUserItem from "../items/OnlineUserItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCar } from "@fortawesome/free-solid-svg-icons"

function Online({ showOnline }) {

    const user = useSelector((state) => state.user.profile)

    const { getUsersOnlineMyZone, onlineList, loading } = useOnline()

    const [me, setMe] = useState(null)
    const [list, setList] = useState([])

    useEffect(() => {
        getUsersOnlineMyZone()
    }, [showOnline])

    useEffect(() => {
        setMe(null)
        const newList = onlineList.filter(item => item.email !== user.email)
        setList(newList)
        onlineList.forEach((u) => {
            if (u.email === user.email) {
                setMe(u)
            }
        })
    }, [onlineList])

    return (
        <div className="online-container">
            <div>
                <div>
                    {me ?
                        <div className="online-my-state-container">
                            <FontAwesomeIcon
                                className="online-icon-my-state"
                                icon={faCar}
                                color="green" />
                            <label className="online-label-my-state">Mi estado: online</label>
                        </div>
                    : 
                        <div className="online-my-state-container">
                            <FontAwesomeIcon
                                icon={faCar}
                                color="red" />
                            <label className="label-my-state">Mi estado: offline</label>
                        </div>
                    }
                </div>
            </div>
            <div>
                <h3>Usuarios online</h3>
                {
                    list && list.length > 0 ? list.map((item) => (
                        <div key={item}>
                            <OnlineUserItem user={item}/>
                        </div>
                    )) : (
                        <div>
                            <label>No hay usuarios online</label>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Online