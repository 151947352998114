import { createSlice } from "@reduxjs/toolkit";

const serviceDaySlice = createSlice({
    name: "serviceDay",
    initialState: {
        data: null
    },
    reducers: {
        setServiceDay: (state, action) => {
            state.data = action.payload
        },
        clearServiceDay: (state) => {
            state.data = null
        }
    }
})

export const { setServiceDay, clearServiceDay } = serviceDaySlice.actions
export default serviceDaySlice.reducer