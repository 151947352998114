import { useState } from "react"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"

export const useEmployees = () => {

    const [loading, setLoading] = useState(false)
    const [license, setLicense] = useState(null)
    const [employeesList, setEmployeesList] = useState([])

    const getLicenseData = async () => {
        
        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.GET_LICENSE_DATA

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                const data = await response.json()
                setLicense(data.license)
                setEmployeesList(data.users)
            }

        } catch (error) {
            console.log("ERROR GETLICENSEDATA")

        } finally {
            setLoading(false)
        }
    }

    const deleteEmployeeFromLicense = async (employee, refresh) => {

        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.DELETE_EMPLOYEE_FROM_LICENSE

        setLoading(true)

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: employee.id
                })
            })

            if (response.ok) {
                refresh()
            }

        } catch (error) {
            console.log("ERROR BORRANDO EMPLEADO DE LA LICENCIA")
        } finally {
            setLoading(false)
        }
    }

    return {
        getLicenseData,
        deleteEmployeeFromLicense,
        license,
        employeesList,
        loading
    }

}