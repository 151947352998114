import React, { useEffect, useState } from "react";
import "../../styles/ServiceDayItem.css"

function ServiceDayItem({ serviceDay, selectedServiceDay }) {

    const [services, setServices] = useState([])
    const [total, setTotal] = useState(0.0);
    const [card, setCard] = useState(0.0);
    const [tips, setTips] = useState(0.0);

    const dayOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const date = new Date(serviceDay?.serviceDayData?.startDate);
    const dayNumber = date.getDay();
    const dayName = dayOfWeek[dayNumber];

    useEffect(() => {
        if (serviceDay.services) {
            setServices(serviceDay.services)
        }
    }, [serviceDay])

    useEffect(() => {
        setSum()
    }, [services])

    const setSum = () => {
        let totalSum = 0.0
        let cardSum = 0.0
        let tipsSum = 0.0

        if (Array.isArray(services) && services.length > 0) {
            services.forEach(service => {
                totalSum += service.price
                tipsSum += service.tips || 0

                if (service.card) {
                    cardSum += service.price
                }
            })
        }

        setTotal(totalSum)
        setCard(cardSum)
        setTips(tipsSum)
    }

    return (
        <div
            className={`item-container ${serviceDay.serviceDayData.id === selectedServiceDay?.serviceDayData?.id ? 'selected' : ''}`}
        >
            <div className="date-container">
                <label>{dayName} || {serviceDay.serviceDayData.startDate}</label>
            </div>
            <div className="resume-container">
                <div className="item-data">
                    <label>Total</label>
                    <label className="number-label">{total.toFixed(2)} €</label>
                </div>
                <div className="item-data">
                    <label>Tarjeta</label>
                    <label className="number-label">{card.toFixed(2)} €</label>
                </div>
                <div className="item-data">
                    <label>Propina</label>
                    <label className="number-label">{tips.toFixed(2)} €</label>
                </div>

            </div>
        </div>
    )

}

export default ServiceDayItem