import { useState } from "react"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import Swal from "sweetalert2"

export const useConfiguration = () => {

    const [currentOnlineStatus, setCurrentOnlineStatus] = useState(false)
    const [currentSharePhoneStatus, setCurrentSharePhoneStatus] = useState(false)
    const [currentShareWithAdmin, setCurrentShareWithAdmin] = useState(false)

    const handleGetSharePreferences = async () => {
        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.GET_SHARE_PREFERENCES

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                const data = await response.json()
                setCurrentOnlineStatus(data.online)
                setCurrentSharePhoneStatus(data.sharePhoneNumber)
                setCurrentShareWithAdmin(data.shareWithAdmins)

            } else {
                console.log("Errorr en la respuesta al obtener las preferencias de estado")
            }
        } catch (error) {
            Swal.fire({
                title: "Error en la respuesta del servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }
    }

    const handleChangeSharePreferences = async (online, sharePhone) => {

        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.CHANGE_SHARE_PREFERENCES

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    online: online,
                    sharePhoneNumber: sharePhone
                })
            })
            
            if (!response.ok) {
                console.log("Error en la respuesta al cambiar las preferencias de estado")
            }

        } catch (error) {
            Swal.fire({
                title: "Error en la respuesta del servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })

        }

    }

    const handleShareWithAdminPreference = async (shareWithAdmin) => {

        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.CHANGE_SHARE_WITH_ADMIN

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    online: false,
                    sharePhoneNumber: false,
                    shareWithAdmins: shareWithAdmin
                })
            })

            if (!response.ok) {
                console.log("Error en la respuesta al cambiar las preferencias de administrador")
            }

        } catch (error) {
            Swal.fire({
                title: "Error en la respuesta del servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }
    }

    return {
        handleGetSharePreferences,
        handleChangeSharePreferences,
        handleShareWithAdminPreference,
        currentOnlineStatus,
        currentSharePhoneStatus,
        currentShareWithAdmin
    }
}