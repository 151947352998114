import Swal from "sweetalert2"

export const errorResponse = (error, setErrors) => {

    const { errorCode, message } = error

    switch (errorCode) {
        //Nombre de usuario no disponible
        case 101:
            setErrors(prevErrors => ({
                ...prevErrors,
                userNameError: `* ${message}`
            }))
            break
        //Email ya registrado
        case 102:
            setErrors(prevErrors => ({
                ...prevErrors,
                emailError: `* ${message}`
            }))
            break
        //Violación de restricción de integridad de datos
        case 103:
            Swal.fire({
                title: "Registro no completado",
                text: message,
                icon: "error",
                confirmButtonText: "Cerrar"
            })
            break
        //Licencia duplicada
        case 104:
            setErrors(prevErrors => ({
                ...prevErrors,
                nLicenseError: `* ${message}`
            }))
            break
        //Contraseña incorrecta
        case 110:
            setErrors(prevErrors => ({
                ...prevErrors,
                passwordError: `* ${message}`,
            }))
            break
        //Usuario no encontrado
        case 111:
            setErrors(prevErrors => ({
                ...prevErrors,
                usernameError: `* ${message}`,
            }))
            break
        //Email no verificado
        case 112:
            setErrors(prevErrors => ({
                ...prevErrors,
                usernameError: `* ${message}`
            }))
            break
        //Email de recuperación no enviado
        case 113:
            Swal.fire({
                title: "Error",
                text: message,
                icon: "error",
                confirmButtonText: "Cerrar"
            })
            break
        case 505:
            Swal.fire({
                title: "Registro no completado",
                text: message,
                icon: "error",
                confirmButtonText: "Cerrar"
            })
            break
        
    }
}