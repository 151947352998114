import React, { useEffect, useState } from "react"
import "../styles/Home.css"
import Aside from "../components/home/Aside";
import Consult from "../components/home/Consult";
import MyEmployees from "../components/home/MyEmployees";
import CurrentDay from "../components/home/CurrentDay";
import ConsultForAdmin from "../components/home/ConsultForAdmin";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faBurger, faCaretDown, faEye, faEyeSlash, faGear, faGlobe, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom";
import { clearUser } from "../redux/userSlice";
import { logoutUser } from "../security/Cookies";
import Online from "../components/home/Online";
import Footer from "../components/common-components/Footer"

function Home() {

    const user = useSelector((state) => state.user.profile)
    const navigation = useNavigate()
    const dispatch = useDispatch()

    const [selectedContent, setSelectedContent] = useState("")
    const [showProfileOptions, setShowProfileOptions] = useState(false)
    const [showOnline, setShowOnline] = useState(false)
    const [showLateralBar, setShowLateralBar] = useState(false)

    useEffect(() => {
        if (user) {
            (user.role === "Admin") 
                ? setSelectedContent("ConsultsForAdmin")
                : setSelectedContent("Consults")
        }
    }, [user])

    const handleContentChange = (content) => {
        setSelectedContent(content)
    }

    const handleShowProfileOptions = () => {
        setShowProfileOptions(!showProfileOptions)
        setShowOnline(false)
    }

    const handleShowOnline = () => {
        setShowOnline(!showOnline)
        setShowProfileOptions(false)
    }

    const handleShowLateralBar = () => {
        setShowLateralBar(!showLateralBar)
    }

    const handleLogout = () => {
        logoutUser()
        dispatch(clearUser())
        navigation("/login")
    }

    const toConfiguration = () => {
        navigation("/configuration")
    }

    const toProfile = () => {
        navigation("/profile")
    }

    return (
        <div className={showLateralBar ? "home-container" : "home-container-lateral-bar-hidden"}>
            <header className="home-header">

                {/* USUARIO SOBRE EL HEADER PARA LAS OPCIONES DE USUARIO */}
                <div className="home-header-icon-container" onClick={handleShowLateralBar}>
                    <FontAwesomeIcon icon={faBars}/>
                </div>

                <h3 className="home-header-title">TAXI TRACKER</h3>

                <div className="home-header-icon-group-container">
                    <div className="home-header-icon-container" onClick={handleShowOnline}>
                        <FontAwesomeIcon icon={faGlobe} />
                    </div>
                    <div className="home-header-icon-container" onClick={handleShowProfileOptions}>
                        <FontAwesomeIcon icon={faCaretDown} />
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                </div>

                <div
                    className={`home-header-profile-options-container ${
                        showProfileOptions ? "visible" : "hidden"
                        }`}
                >
                    <div className="home-header-profile-data-container">
                        <label>{user.username}</label>
                        <label>{user.name} {user.surname}</label>
                        <label>{user.email}</label>
                        <label>{user.role}</label>
                    </div>
                    <div className="home-header-single-option-container"
                        onClick={toProfile}>
                        <FontAwesomeIcon icon={faUser} />
                        <label>Perfil</label>
                    </div>
                    <div className="home-header-single-option-container"
                        onClick={toConfiguration}>
                        <FontAwesomeIcon icon={faGear} />
                        <label>Configuración</label>
                    </div>
                    <div className="home-header-single-option-container"
                        onClick={handleLogout}>
                        <FontAwesomeIcon icon={faRightFromBracket} />
                        <label>Logout</label>
                    </div>
                </div>

                <div
                    className={`home-header-profile-options-container ${
                        showOnline ? "visible" : "hidden"
                        }`}
                >
                    <Online showOnline={showOnline}/>
                </div>

            </header>

            <Aside
                onContentChange={handleContentChange}
                showLabels={showLateralBar}
                selectedOption={selectedContent}/>

            {selectedContent === "ConsultsForAdmin" && <ConsultForAdmin />}
            {selectedContent === "Consults" && <Consult />}
            {selectedContent === "CurrentDay" && <CurrentDay />}
            {selectedContent === "MyEmployees" && <MyEmployees />}
            
            <Footer />
        </div>
    );
}

export default Home