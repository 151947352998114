import { useEffect } from "react"
import "../../styles/ServiceItem.css"

const ServiceItem = ({ service, index, selectedService }) => {

    const formattedPrice = parseFloat(service.price).toFixed(2)
    const formattedTips = service.tips > 0 ? parseFloat(service.tips).toFixed(2)
        : parseFloat(0.0).toFixed(2)

    return (
        <div 
            className={`service-item-container ${service.id === selectedService?.id ? 'selected' : ''}`}
        >
            <div className="service-item-index-container">
                <label className="service-item-index-label">{index + 1}</label>
            </div>
            <div className="service-item-data-container">
                <label className="service-item-price-label">{formattedPrice} €</label>
                <label className="service-item-tips-label">{formattedTips} €</label>
            </div>
            {service.card && (
                <div className="service-item-card-container">
                    <label className="service-item-card-label">T</label>
                </div>
            )}
        </div>
    )
    
}

export default ServiceItem