export const getCurrentDate = () => {
    const today = new Date()

    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, "0")
    const day = String(today.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
}

export const getPreviousDate = () => {
    const today = new Date()

    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, "0")
    const day = String(today.getDate() - 1).padStart(2, "0")

    return `${year}-${month}-${day}`
}

export const getCurrentTime = () => {
    const today = new Date()

    const hours = String(today.getHours()).padStart(2, "0")
    const minutes = String(today.getMinutes()).padStart(2, "0")
    const seconds = String(today.getSeconds()).padStart(2, "0")

    return `${hours}:${minutes}:${seconds}`
}