import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/common-components/Footer"
import "../styles/Welcome.css"
import TaxiIcon from "../images/taxi-icon.png"

function Welcome() {
    return (
        <div className="welcome-container">
            <header className="welcome-header">
                <h1>TAXI TRACKER</h1>
            </header>

            <main className="welcome-content">
                <h1 className="welcome-title">Bienvenido a Taxi Tracker</h1>

                <img
                    className="welcome-img"
                    src={TaxiIcon}
                    alt="icon" />

                <p className="welcome-message"></p>
                <Link to="/login">
                    <button className="welcome-button">Entrar</button>
                </Link>
            </main>

            <Footer />
        </div>
    )
}

export default Welcome