import { useState } from "react"
import { ENDPOINT } from "../util/enpoints.js"
import { fetchWithTimeout } from "../util/fetchWithTimeout.js"
import { REGEX } from "../util/constants.js"
import { loginUser } from "../security/Cookies.js"
import { useDispatch } from "react-redux"
import { setUser } from "../redux/userSlice.js"
import { useNavigate } from "react-router-dom"
import { errorResponse } from "../util/errors.js"

export const useLogin = () => {

    const dispatch = useDispatch()
    const navigation = useNavigate()

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        usernameError: "",
        passwordError: ""
    })

    const handleLogin = async (username, password) => {

        if (!validateInput(username, password)) return

        setLoading(true)

        const endPoint = ENDPOINT.LOGIN

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userName: username,
                    password: password
                })
            })

            if (response.ok) {
                const data = await response.json()
                console.log(data)

                loginUser(data.jwt)

                const user = {
                    name: data.name,
                    surname: data.surname,
                    username: data.userName,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    nLicense: data.nLicense,
                    role: data.role
                }
                dispatch(setUser(user))
                navigation('/home')
            } else {
                const data = await response.json()
                errorResponse(data, setErrors)
            }

        } catch (error) {
            console.log("Catch error")
        } finally {
            setLoading(false)
        }
    }

    const validateInput = (username, password) => {
        let isValid = true
        setErrors({ usernameError: "", passwordError: "" })

        if (!username) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                usernameError: "* Ingrese un nombre de usuario"
            }))
            isValid = false
        }

        if (!password) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordError: "* Ingrese una contraseña"
            }))
            isValid = false
        } else if (password.length < 4 || password.length > 20) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe tener entre 4 y 20 caracteres"
            }))
            isValid = false

        } else if (!REGEX.PASSWORD.test(password)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordError: "* La contraseña debe contener solo letrea mayúsculas o minúsculas, dígitos y los caracteres especiales: !@#$%&,.-_Çç"
            }))
            isValid = false
        }

        return isValid
    }

    return {
        handleLogin,
        loading,
        errors
    }
}