import { getCurrentDate, getPreviousDate } from "./dates";

//const BASE_URL = "http://localhost:8087"
const BASE_URL = "https://api.taxi-tracker.com"

export const ENDPOINT = {

    // useLogin y useRegister
    LOGIN: `${BASE_URL}/auth/login`,
    REGISTER_USER: `${BASE_URL}/auth/register-user`,
    REGISTER_LICENSE: `${BASE_URL}/auth/register-license`,
    REGISTER_EMPOLOYE: `${BASE_URL}/api/users`,

    // useServiceDay
    CHECK_SERVICE_DAY_CURRENT: `${BASE_URL}/api/service-days/byStartDate/${getCurrentDate()}`,
    CHECK_SERVICE_DAY_PREVIOUS: `${BASE_URL}/api/service-days/byStartDate/${getPreviousDate()}`,
    INIT_SERVICE_DAY: `${BASE_URL}/api/service-days`,
    FINISH_SERVICE_DAY: `${BASE_URL}/api/service-days/`, // + ${serviceDay.id}
    OPEN_SERVICE_DAY: `${BASE_URL}/api/service-days/`, // + ${serviceDay.id}
    DELETE_SERVICE_DAY: `${BASE_URL}/api/service-days/`, // + ${serviceDay.id}

    // useServiceList
    GET_SERVICE_LIST: `${BASE_URL}/api/services/byServiceDayId/`, // + ${serviceDay.id}

    // useService
    ADD_SERVICE: `${BASE_URL}/api/services`,
    EDIT_SERVICE: `${BASE_URL}/api/services/`, // + ${service.id}
    DELETE_SERVICE: `${BASE_URL}/api/services/`, // + ${service.id}

    // useConsult
    CONSULT: `${BASE_URL}/api/service-days/betweenDates/`, // + ${startDate}/${endDate}
    CONSULT_FOR_ADMIN: `${BASE_URL}/api/service-days/betweenDatesForAdmin/`, // + ${startDate}/${endDate}

    // useEmployees
    GET_LICENSE_DATA: `${BASE_URL}/api/licenses/my-license`,
    DELETE_EMPLOYEE_FROM_LICENSE: `${BASE_URL}/api/users/delete-employee-from-license`,

    // useOnline
    GET_USERS_ONLINE_MY_ZONE: `${BASE_URL}/api/status/my-zone`,

    // useUser
    GET_USER: `${BASE_URL}/api/users/getUser`,
    EDIT_USER: `${BASE_URL}/api/users/edit-user`,
    DELETE_USER: `${BASE_URL}/api/users/delete-user`,

    // usePassword
    CHANGE_PASSWORD: `${BASE_URL}/api/users/change-password`,
    RECOVER_PASSWORD: `${BASE_URL}/auth/send-recover-password-email`,

    // usePreferences
    GET_SHARE_PREFERENCES: `${BASE_URL}/api/status/get-online-status`,
    CHANGE_SHARE_PREFERENCES: `${BASE_URL}/api/status/online`,
    CHANGE_SHARE_WITH_ADMIN: `${BASE_URL}/api/status/set-share-with-admin`,

    // useLicense
    MODIFY_ASOCIATED_LICENSE: `${BASE_URL}/api/users/modify-asociated-license`,
    DELETE_ASOCIATED_LICENSE: `${BASE_URL}/api/users/delete-asociated-license`

}