import { useEffect, useState } from "react"
import { useConfiguration } from "../../hooks/useConfiguration"
import "../../styles/Configuration.css"
import { useUser } from "../../hooks/useUser"
import Swal from "sweetalert2"
import { useSelector } from "react-redux"

function Configuration() {

    const { handleGetSharePreferences, handleChangeSharePreferences, handleShareWithAdminPreference,
        currentOnlineStatus, currentSharePhoneStatus, currentShareWithAdmin
    } = useConfiguration()

    const { handleDeleteUser } = useUser()

    const user = useSelector((state) => state.user.profile)

    const [onlineState, setOnlineState] = useState(false)
    const [sharePhoneState, setSharePhoneState] = useState(false)
    const [shareWithAdmin, setShareWithAdmin] = useState(false)

    const onlineSwitch = () => setOnlineState(!onlineState)
    const sharePhoneSwitch = () => setSharePhoneState(!sharePhoneState)
    const shareWithAdminSwitch = () => setShareWithAdmin(!shareWithAdmin)
    
    useEffect(() => {
        handleGetSharePreferences()
    }, [])

    useEffect(() => {
        setOnlineState(currentOnlineStatus)
        setSharePhoneState(currentSharePhoneStatus)
        setShareWithAdmin(currentShareWithAdmin)
    }, [currentOnlineStatus, currentSharePhoneStatus, currentShareWithAdmin])

    useEffect(() => {
        if (!onlineState && sharePhoneState) {
            setSharePhoneState(false)
            handleChangeSharePreferences(onlineState, false)
        } else handleChangeSharePreferences(onlineState, sharePhoneState)
    }, [onlineState])

    useEffect(() => {
        if (!onlineState && sharePhoneState) {
            setOnlineState(true)
        }
        handleChangeSharePreferences(onlineState, sharePhoneState)
    }, [sharePhoneState])

    useEffect(() => {
        handleShareWithAdminPreference(shareWithAdmin)
    }, [shareWithAdmin])

    const deleteUser = () => {

        if (user && user.role === "Admin") {
            Swal.fire({
                title: "Eliminar usuario",
                text: "Al confirmar la eliminación de la cuenta, al ser usuario administardor," + 
                    " si no existe otra cuenta de administrador se eliminará la licencia asociada" +
                    " a esta cuenta además de todos los servicios. ¿Estás seguro de eliminar la cuenta y la licencia?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    handleDeleteUser()
                }
            })
        } else {
            Swal.fire({
                title: "Eliminar usuario",
                text: "Se borrarán todos los registros de servicios asociados a la cuenta al eliminarla. " +
                    "¿Estás seguro de eliminar la cuenta?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Eliminar",
                cancelButtonText: "Cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    handleDeleteUser()
                }
            })
        }
    }

    return (
        <div className="configuration-container">
            <header className="configuration-header-container">
                <label className="configuration-header-title">Configuración</label>
            </header>

            <div className="configuration-main-container">
                <h2 className="configuration-title">Mostrar conexión</h2>
                <div className="single-configuration-container">
                    <input
                        className="configuration-input"
                        type="checkbox"
                        checked={onlineState}
                        onChange={onlineSwitch} />
                    <label className="configuration-label">Mostrarme online</label>
                </div>
                <div className="single-configuration-container">
                    <input
                        className="configuration-input"
                        type="checkbox"
                        checked={sharePhoneState}
                        onChange={sharePhoneSwitch} />
                    <label className="configuration-label">Compartir teléfono</label>
                </div>

                <h2 className="configuration-title">Compartir ganancias</h2>
                <div className="single-configuration-container">
                    <input
                        className="configuration-input"
                        type="checkbox"
                        checked={shareWithAdmin}
                        onChange={shareWithAdminSwitch} />
                    <label className="configuration-label">Compartir ganancias con administradores</label>
                </div>

                <button className="configuration-button-delete-account" onClick={deleteUser}>Eliminar cuenta</button>
            </div>
        </div>
    )

}

export default Configuration