import { useState } from "react"
import { useSelector } from "react-redux"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import Swal from "sweetalert2"

export const useServiceList = () => {

    const serviceDay = useSelector((state) => state.serviceDay.data)

    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchServices = async () => {

        if (!serviceDay) return

        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.GET_SERVICE_LIST + `${serviceDay.id}`

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                const data = await response.json()
                setServices(data)
                console.log(data)
            }
        } catch (error) {
            Swal.fire({
                title: "Error obteniendo la lista de servicios",
                text: error,
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        } finally {
            setLoading(false)
        }
    }

    return {
        fetchServices,
        services,
        loading
    }
}