import { useEffect, useState } from "react"
import { REGEX } from "../util/constants"
import { ENDPOINT } from "../util/enpoints"
import { useSelector } from "react-redux"
import { getToken } from "../security/Cookies"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import Swal from "sweetalert2"

export const useService = () => {

    const serviceDay = useSelector((state) => state.serviceDay.data)

    const [serviceDayFinished, setServiceDayFinished] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [errors, setErrors] = useState({
        priceError: "",
        tipsError: ""
    })

    useEffect(() => {
        if (serviceDay?.endDate != null) setServiceDayFinished(true)
    }, [serviceDay])

    const handleAddService = async (price, tips, card, fetchServices) => {

        if (!validateInput(price, tips)) return

        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.ADD_SERVICE

        if (serviceDay.endDate != null) {
            Swal.fire({
                title: "Día finalizado",
                text: "Debe reabrir el día para poder introducir nuevos servicios",
                icon: "warning",
                confirmButtonText: "Cerrar"
            })

        } else if (serviceDay != null) {
            const newService = {
                serviceDay: serviceDay,
                price: price.replace(/,/g, '.'),
                card: card,
                tips: tips.replace(/,/g, '.')
            }

            try {
                const response = await fetchWithTimeout(endPoint, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(newService)
                })

                if (!response.ok) {
                    Swal.fire({
                        title: "Servicio no guardado",
                        icon: "error",
                        confirmButtonText: "Cerrar"
                    })
                } else {
                    fetchServices()
                }

            } catch (error) {
                Swal.fire({
                    title: "Error de conexión",
                    icon: "error",
                    confirmButtonText: "Cerrar"
                })
            } finally {
                setLoading(false)
            }
        } else {
            Swal.fire({
                title: "Servicio no guardado",
                text: "No puedes guardar un servicio si no has iniciado el día",
                icon: "warning",
                confirmButtonText: "Cerrar"
            })
            setLoading(false)
        }

    }

    const handleEditService = async (service, price, tips, card, fetchServices, clearSelectedService) => {

        if (!service) {
            Swal.fire({
                title: "El servicio seleccionado no está disponible",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
            return
        }

        if (!validateInput(price, tips)) return

        const token = getToken()
        if (!token) return

        setLoading(true)

        const endPoint = ENDPOINT.EDIT_SERVICE + `${service.id}`

        const editedService = {
            price: String(price).includes(",") ? String(price).replace(/,/g, '.') : String(price),
            card: card,
            tips: String(tips).includes(",") ? String(tips).replace(/,/g, '.') : String(tips)
        }

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(editedService)
            })

            if (!response.ok) {
                Swal.fire({
                    title: "Servicio no editado",
                    icon: "error",
                    confirmButtonText: "Cerrar"
                })
            } else {
                fetchServices()
            }

        } catch(error) {
            Swal.fire({
                title: "Error de conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })

        } finally {
            setLoading(false)
            clearSelectedService()
        }

    }

    const handleDeleteService = async (service, fetchServices, clearSelectedService) => {

        if (!service) {
            Swal.fire({
                title: "El servicio seleccionado no está disponible",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
            return
        }

        setLoading(true)

        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.DELETE_SERVICE + `${service.id}`

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
            })

            if (response.ok) {
                fetchServices()

            } else {
                Swal.fire({
                    title: "Servicio no borrado",
                    icon: "error",
                    confirmButtonText: "Cerrar"
                })
            }

        } catch (error) {
            Swal.fire({
                title: "Error de conexión",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        } finally {
            setLoading(false)
            clearSelectedService()
        }

    }

    const validateInput = (price, tips) => {
        let isValid = true
        setErrors({ priceError: "", tipsError: "" })

        if (price <= 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                priceError: "* Requerido"
            }))
            isValid = false

        } else if (!REGEX.DECIMAL.test(price)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                priceError: "* Solo números con o sin decimales"
            }))
        }

        if (tips === "") {

        } else if (!REGEX.DECIMAL.test(tips)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                tipsError: "* Solo números con o sin decimales"
            }))
            isValid = false
        }

        return isValid
    }

    return {
        handleAddService,
        handleEditService,
        handleDeleteService,
        serviceDayFinished,
        loading,
        errors
    }
}