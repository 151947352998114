import "../../styles/OnlineUserItem.css"
import { faCar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const OnlineUserItem = ({ user }) => {

    return (
        <div className="online-user-item-container">
            <FontAwesomeIcon
                icon={faCar}
                color="green" />
            {user.license ? <label>{user.license.licenseNumber}</label>
            : <label>    </label>}
            <label>{user.name} {user.surname}</label>
            <label>{user.phoneNumber ? <label>{user.phoneNumber}</label> : null}</label>
        </div>
    )

}

export default OnlineUserItem