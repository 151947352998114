import { useSelector } from "react-redux"
import "../../styles/ServiceDayState.css"
import "../../styles/theme.css"
import { useServiceDay } from "../../hooks/useServiceDay"
import { useEffect } from "react"
import { PulseLoader } from "react-spinners"

function ServiceDayState() {

    const serviceDay = useSelector((state) => state.serviceDay.data)

    const { handleFinishServiceDay, handleReOpenServiceDay, serviceDayFinished, loading} = useServiceDay()

    useEffect(() => {
        console.log("En ServiceDayState", serviceDayFinished)
    }, [serviceDayFinished])

    return (
        <div>
            {serviceDay ? (
                <div className="service-day-state-main-container">
                    <div className="service-day-state-data-container">
                    <label className="service-day-state-label">Inicio</label>
                        <label className="service-day-state-label">{serviceDay.startDate}</label>
                        <label className="service-day-state-label">{serviceDay.startHour}</label>
                    </div>
                    <div className="service-day-state-data-container">
                        <label className="service-day-state-label">Fin</label>
                        <label className="service-day-state-label">{serviceDay.endDate ? serviceDay.endDate : "---- - -- - --"}</label>
                        <label className="service-day-state-label">{serviceDay.endHour ? serviceDay.endHour : "--:--:--"}</label>
                    </div>
                    <div>
                        {
                            serviceDayFinished
                                ? <button className="service-day-state-button" onClick={handleReOpenServiceDay}>
                                    {
                                        loading ? (
                                            <PulseLoader
                                                color="var(--green1)"
                                                loading={loading}
                                                size={16} />
                                        ) : "Reabrir"
                                    }
                                </button>

                                : <button className="service-day-state-button" onClick={handleFinishServiceDay}>
                                    {
                                        loading ? (
                                            <PulseLoader
                                                color="var(--green1)"
                                                loading={loading}
                                                size={16} />
                                        ) : "Finalizar"
                                    }
                                </button>
                        }
                    </div>
                </div>
                
            ) : null}
        </div>
    )
}

export default ServiceDayState