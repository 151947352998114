import React, { useState } from "react";
import "../../styles/Forms.css"
import "../../styles/theme.css"
import { useRegister } from "../../hooks/useRegister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { PulseLoader } from "react-spinners";

function FormLicense() {

    const { handleRegisterLicense, errorsLicense, loading } = useRegister()

    const [nLicense, setNLicense] = useState("")
    const [town, setTown] = useState("")
    const [province, setProvince] = useState("")
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const onRegister = (event) => {
        event.preventDefault()
        handleRegisterLicense(nLicense, name, surname, email, phoneNumber, password, confirmPassword, town, province)
    }

    const changePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const changeConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }
    
    return (

        <form className="form-license-container" onSubmit={onRegister}>

            <section className="form-license-section-1-container">
                <h3>Datos de licencia</h3>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Número de licencia</label>
                    <input
                        className={`form-license-register-input ${errorsLicense.nLicenseError ? "error" : ""}`}
                        type="text"
                        placeholder="Número de licencia"
                        value={nLicense}
                        onChange={(e) => setNLicense(e.target.value)}
                    />
                    {errorsLicense.nLicenseError ? <label className="form-license-error-label">{errorsLicense.nLicenseError}</label> : null}
                </div>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Localidad</label>
                    <input
                        className={`form-license-register-input ${errorsLicense.townError ? "error" : ""}`}
                        type="text"
                        placeholder="Localidad"
                        value={town}
                        onChange={(e) => setTown(e.target.value)}
                    />
                    {errorsLicense.townError ? <label className="form-license-error-label">{errorsLicense.townError}</label> : null}
                </div>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Provincia</label>
                    <input
                        className={`form-license-register-input ${errorsLicense.provinceError ? "error" : ""}`}
                        type="text"
                        placeholder="Provincia"
                        value={province}
                        onChange={(e) => setProvince(e.target.value)}
                    />
                    {errorsLicense.provinceError ? <label className="form-license-error-label">{errorsLicense.provinceError}</label> : null}
                </div>

            </section>

            <section className="form-license-section-2-container">
                <h3>Datos de propietario</h3>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Nombre</label>
                    <input
                        className={`form-license-register-input ${errorsLicense.ownerNameError ? "error" : ""}`}
                        type="text"
                        placeholder="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    {errorsLicense.ownerNameError ? <label className="form-license-error-label">{errorsLicense.ownerNameError}</label> : null}
                </div>
                <div className="form-license-single-data-container">
                <label className="form-license-register-label">Apellidos</label>
                    <input
                        className={`form-license-register-input ${errorsLicense.ownerSurnameError ? "error" : ""}`}
                        type="text"
                        placeholder="Apellidos"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                    {errorsLicense.ownerSurnameError ? <label className="form-license-error-label">{errorsLicense.ownerSurnameError}</label> : null}
                </div>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Email</label>
                    <input
                        className={`form-license-register-input ${errorsLicense.emailError ? "error" : ""}`}
                        type="text"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errorsLicense.emailError ? <p>{errorsLicense.emailError}</p> : null}
                </div>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Teléfono (opcional)</label>
                    <input
                        className={`form-license-register-input ${errorsLicense.phoneNumberError ? "error" : ""}`}
                        type="text"
                        placeholder="Teléfono"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errorsLicense.phoneNumberError ? <label className="form-license-error-label">{errorsLicense.phoneNumberError}</label> : null}
                </div>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Contraseña</label>
                    <div className="form-license-password-input-container">
                        <input
                        className={`form-license-register-input ${errorsLicense.passwordError ? "error" : ""}`}
                            type={showPassword ? "text" : "password"}
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FontAwesomeIcon
                            className="form-license-password-icon"
                            icon={showPassword ? faEyeSlash : faEye}
                            color="black"
                            onClick={changePasswordVisibility} />
                    </div>
                    {errorsLicense.passwordError ? <label className="form-license-error-label">{errorsLicense.passwordError}</label> : null}
                </div>
                <div className="form-license-single-data-container">
                    <label className="form-license-register-label">Contraseña</label>
                    <div className="form-license-password-input-container">
                        <input
                        className={`form-license-register-input ${errorsLicense.confirmPasswordError ? "error" : ""}`}
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirmar contraseña"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <FontAwesomeIcon
                            className="form-license-password-icon"
                            icon={showConfirmPassword ? faEyeSlash : faEye}
                            color="black"
                            onClick={changeConfirmPasswordVisibility} />

                    </div>
                    {errorsLicense.confirmPasswordError ? <label className="form-license-error-label">{errorsLicense.confirmPasswordError}</label> : null}
                </div>
            </section>

            <div className="form-license-register-button-container">
                <button className="form-license-register-button">
                    {
                        loading ? (
                            <PulseLoader
                                color="var(--green2)"
                                loading={loading}
                                size={18} />
                        ) : "Registrar"
                    }
                </button>
            </div>

        </form>
    )
}

export default FormLicense