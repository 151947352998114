import Swal from "sweetalert2"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import { useState } from "react"

export const useConsult = () => {

    const [loading, setLoading] = useState(false)
    const [serviceDays, setServiceDays] = useState([])
    const [serviceDaysMap, setServiceDaysMap] = useState({})

    const makeConsult = async (date1, date2) => {
        const token = getToken()
        if (!token) return

        if (validateDates(date1, date2)) {

            setLoading(true)

            const startDate = date1.toISOString().substring(0, 10)
            const endDate = date2.toISOString().substring(0, 10)

            const endPoint = ENDPOINT.CONSULT + `${startDate}/${endDate}`

            try {
                const response = await fetchWithTimeout(endPoint, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                })

                if (response.ok) {
                    const data = await response.json()
                    setServiceDays(data)
                    console.log(data)

                } else {
                    console.log("Response no OK MAKE CONSULT")
                }

            } catch (error) {
                console.log("Catch de MAKE CONSULT")
            } finally {
                setLoading(false)
            }
        }
    }

    const makeConsultForAdmin = async (date1, date2) => {
        const token = getToken()
        if (!token) return

        if (validateDates(date1, date2)) {

            setLoading(true)

            const startDate = date1.toISOString().substring(0, 10)
            const endDate = date2.toISOString().substring(0, 10)

            const endPoint = ENDPOINT.CONSULT_FOR_ADMIN + `${startDate}/${endDate}`

            try {
                const response = await fetchWithTimeout(endPoint, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                })

                if (response.ok) {
                    const data = await response.json()
                    //console.log("Datos completos NEW CONSULT:", JSON.stringify(data, null, 2))
                    setServiceDaysMap(data)
                    
                } else {
                    console.log("Response no OK MAKE CONSULT FOR ADMIN")
                }

            } catch (error) {
                console.log("Catch de MAKE CONSULT FOR ADMIN")
            } finally {
                setLoading(false)
            }
        }
    }

    const validateDates = (date1, date2) => {
        let isValid = false

        if (date1 <= date2) {
            isValid = true
        } else {
            Swal.fire({
                title: "Fechas inválidas",
                text: "La fecha de inicio no puede ser posterior a la fecha de fin",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }

        return isValid
    }

    return {
        makeConsult,
        makeConsultForAdmin,
        serviceDays,
        serviceDaysMap,
        loading
    }
}