import React, { useEffect } from "react";
import "../../styles/Home.css"
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus, faGlobe, faMagnifyingGlass, faUsers } from "@fortawesome/free-solid-svg-icons";

function Aside({ onContentChange, showLabels, selectedOption }) {

    const user = useSelector((state) => state.user.profile)

    return (
        <aside className={`sidebar ${showLabels ? 'expanded' : 'collapsed'}`}>
            {user && user.role === "Admin" ? (
                <ul className="sidebar-table">
                    <div
                        className={`sidebar-table-element ${selectedOption === "ConsultsForAdmin" ? "selected" : ""}`}
                        onClick={() => onContentChange("ConsultsForAdmin")}
                    >
                        <div className={`sidebar-icon-container ${showLabels ? 'expand' : 'collapse'}`}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </div>
                        {showLabels ? (
                            <label className="sidebar-table-element-label">
                                Consultas
                            </label>
                        ) : null}
                    </div>
                    <div
                        className={`sidebar-table-element ${selectedOption === "CurrentDay" ? "selected" : ""}`}
                        onClick={() => onContentChange("CurrentDay")}
                    >
                        <div className={`sidebar-icon-container ${showLabels ? 'expand' : 'collapse'}`}>
                            <FontAwesomeIcon icon={faCalendarPlus} />
                        </div>
                        {showLabels ? (
                            <label className="sidebar-table-element-label">
                                Día actual
                            </label>
                        ) : null}
                    </div>
                    <div
                        className={`sidebar-table-element ${selectedOption === "MyEmployees" ? "selected" : ""}`}
                        onClick={() => onContentChange("MyEmployees")}
                    >
                        <div className={`sidebar-icon-container ${showLabels ? 'expand' : 'collapse'}`}>
                            <FontAwesomeIcon icon={faUsers} />
                        </div>
                        {showLabels ? (
                            <label className="sidebar-table-element-label">
                                Mis empleados
                            </label>
                        ) : null}
                    </div>
                    
                </ul>
            ) : (
                <ul className="sidebar-table">
                    <div
                        className={`sidebar-table-element ${selectedOption === "Consults" ? "selected" : ""}`}
                        onClick={() => onContentChange("Consults")}
                    >
                        <div className={`sidebar-icon-container ${showLabels ? 'expand' : 'collapse'}`}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </div>
                        {showLabels ? (
                            <label className="sidebar-table-element-label">
                                Consultas
                            </label>
                        ) : null}
                    </div>
                    <div
                        className={`sidebar-table-element ${selectedOption === "CurrentDay" ? "selected" : ""}`}
                        onClick={() => onContentChange("CurrentDay")}
                    >
                        <div className={`sidebar-icon-container ${showLabels ? 'expand' : 'collapse'}`}>
                            <FontAwesomeIcon icon={faCalendarPlus} />
                        </div>
                        {showLabels ? (
                            <label className="sidebar-table-element-label">
                                Día actual
                            </label>
                        ) : null}
                    </div>
                </ul>
            )}
            
        </aside>
    )
}

export default Aside