import { useState } from "react"
import { REGEX } from "../util/constants"
import { getToken } from "../security/Cookies"
import { ENDPOINT } from "../util/enpoints"
import { fetchWithTimeout } from "../util/fetchWithTimeout"
import Swal from "sweetalert2"
import { errorResponse } from "../util/errors"

export const useLicense = () => {

    const [licenseModified, setLicenseModified] = useState(false)
    const [errorsLicense, setErrorsLicense] = useState({
        licenseTokenError: ""
    })

    const handleModifyAsociatedLicense = async (licenseToken, role) => {

        if (!validateModifyAsociatedLicenseForm(licenseToken, role)) return

        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.MODIFY_ASOCIATED_LICENSE

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: `${licenseToken}`
            })

            if (response.ok) {
                setLicenseModified(!licenseModified)
            } else {
                const data = await response.json()
                errorResponse(data, errorsLicense)
            }

        } catch (error) {
            Swal.fire({
                title: "Error de conexión con el servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }
    }

    const handleDeleteAsociatedLicense = async () => {

        const token = getToken()
        if (!token) return

        const endPoint = ENDPOINT.DELETE_ASOCIATED_LICENSE

        try {
            const response = await fetchWithTimeout(endPoint, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                setLicenseModified(!licenseModified)
            } else {
                const data = await response.json()
                errorResponse(data, errorsLicense)
            }

        } catch (error) {
            Swal.fire({
                title: "Error de conexión con el servidor",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
        }
    }

    const validateModifyAsociatedLicenseForm = (licenseToken, role) => {

        let isValid = true

        if (!licenseToken) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                licenseTokenError: "* Requerido"
            }))
            isValid = false
        } else if (!REGEX.LICENSE_TOKEN.test(licenseToken)) {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                licenseTokenError: "* Token no válido"
            }))
            Swal.fire({
                title: "Token no válido",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
            isValid = false
        } else if (role === "Admin") {
            setErrorsLicense((prevErrors) => ({
                ...prevErrors,
                licenseTokenError: "* No puedes modificar tu licencia siendo administrador"
            }))
            Swal.fire({
                title: "No puedes modificar tu licencia siendo administrador",
                icon: "error",
                confirmButtonText: "Cerrar"
            })
            isValid = false
        }

        return isValid
    }

    return {
        handleModifyAsociatedLicense,
        handleDeleteAsociatedLicense,
        licenseModified,
        errorsLicense
    }
}