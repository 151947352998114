import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../../styles/ChangePassword.css"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { usePassword } from "../../hooks/usePassword"
import { useNavigate } from "react-router-dom"

function ChangePassword() {

    const { handleChangePassword, errors, loading } = usePassword()

    const [password, setPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")

    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)
    const handleShowPassword = () => setShowPassword(!showPassword)
    const handleShowNewPassword = () => setShowNewPassword(!showNewPassword)
    const handleShowConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword)

    const changePassword = (event) => {
        event.preventDefault()
        handleChangePassword(password, newPassword, confirmNewPassword)
    }


    return (
        <div className="change-password-container">
            <header className="change-password-header-container">
                <label className="change-password-header-title">Cambiar contraseña</label>
            </header>

            <body className="change-password-body-container">
                <form className="change-password-form-container" onSubmit={changePassword}>
                    <div className="change-password-section-container">
                        <label>Contraseña actual</label>
                        <div className="change-password-input-container">
                            <input
                                className={`change-password-input ${errors.passwordError ? "error" : ""}`}
                                type={showPassword ? "text" : "password"}
                                placeholder="Contraseña actual"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                            <FontAwesomeIcon
                                className="change-password-icon"
                                onClick={handleShowPassword}
                                icon={showPassword ? faEyeSlash : faEye}
                                color="black" />
                        </div>
                        {errors.passwordError ? <label className="change-password-label-error">{errors.passwordError}</label> : null}
                    </div>
                    <div className="change-password-section-container">
                        <label>Nueva contraseña</label>
                        <div className="change-password-input-container">
                            <input
                                className={`change-password-input ${errors.newPasswordError ? "error" : ""}`}
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Nueva contraseña"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)} />
                            <FontAwesomeIcon
                                className="change-password-icon"
                                onClick={handleShowNewPassword}
                                icon={showNewPassword ? faEyeSlash : faEye}
                                color="black" />
                        </div>
                        {errors.newPasswordError ? <label className="change-password-label-error">{errors.newPasswordError}</label> : null}
                    </div>
                    <div className="change-password-section-container">
                        <label>Confirmar nueva contraseña</label>
                        <div className="change-password-input-container">
                            <input
                                className={`change-password-input ${errors.confirmNewPasswordError ? "error" : ""}`}
                                type={showConfirmNewPassword ? "text" : "password"}
                                placeholder="Confirmar nueva contraseña"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)} />
                            <FontAwesomeIcon
                                className="change-password-icon"
                                onClick={handleShowConfirmNewPassword}
                                icon={showConfirmNewPassword ? faEyeSlash : faEye}
                                color="black" />
                        </div>
                        {errors.confirmNewPasswordError ? <label className="change-password-label-error">{errors.confirmNewPasswordError}</label> : null}
                    </div>

                    <button className="change-password-button">Cambiar contraseña</button>
                </form>
            </body>
        </div>
    )
}

export default ChangePassword