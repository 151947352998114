import "../../styles/Profile.css"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useUser } from "../../hooks/useUser"
import Swal from "sweetalert2"
import { useLicense } from "../../hooks/useLicense"
import { useNavigate } from "react-router-dom"

function Profile() {

    const navigation = useNavigate()

    const { handleGetUser, handleUpdateUser, user, userModified, errors, loading} = useUser()
    const { handleModifyAsociatedLicense, handleDeleteAsociatedLicense, licenseModified, errorsLicense} = useLicense()

    const [username, setUsername] = useState("Username")
    const [name, setName] = useState("Name")
    const [surname, setSurname] = useState("Surname")
    const [email, setEmail] = useState("Email")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [role, setRole] = useState("Role")
    const [licenseNumber, setLicenseNumber] = useState("")
    const [licenseToken, setLicenseToken] = useState("")

    const [editProfile, setEditProfile] = useState(false)
    const handleEditProfile = () => setEditProfile(!editProfile)
    const toChangePassword = () => navigation("/change-password")

    useEffect(() => {
        handleGetUser()
    }, [])

    useEffect(() => {
        handleGetUser()
    }, [licenseModified, editProfile])

    useEffect(() => {
        setEditProfile(false)
    }, [userModified])

    useEffect(() => {
        if (user) {
            setUsername(user.username)
            setName(user.name)
            setSurname(user.surname)
            setEmail(user.email)
            if (user.phoneNumber) setPhoneNumber(user.phoneNumber)
            setRole(user.role)
            if (user.license) {
                setLicenseNumber(user.license.licenseNumber)
                setLicenseToken(user.license.token)
            }
        }
    }, [user])

    const updateUser = () => {
        handleUpdateUser(name, surname, username, email, phoneNumber)
    }

    const modifyLicense = () => {
        if (licenseToken) {
            Swal.fire({
                title: "Modificar licencia",
                text: "Introduce un nuevo token de licencia",
                input: "text",
                inputPlaceholder: "Token",
                inputValue: `${licenseToken}`,
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: "Guardar",
                cancelButtonText: "Cancelar",
                denyButtonText: "Eliminar licencia"
            }).then((result) => {
                if (result.isConfirmed) {
                    handleModifyAsociatedLicense(result.value, role)
    
                } else if (result.isDismissed) {
    
                } else if (result.isDenied) {
                    setLicenseToken("")
                    handleDeleteAsociatedLicense()
                }
            })
        } else {
            Swal.fire({
                title: "Modificar licencia",
                text: "Introduce un nuevo token de licencia",
                input: "text",
                inputPlaceholder: "Token",
                inputValue: `${licenseToken}`,
                showCancelButton: true,
                confirmButtonText: "Guardar",
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    handleModifyAsociatedLicense(result.value, role)
                }
            })
        }
    }

    return (
        <div className="profile-container">
            <header className="profile-header-container">
                <label className="profile-header-title">Perfil</label>
            </header>

            <body className="profile-body-container">
                <div className="profile-icon-container">
                    <FontAwesomeIcon icon={faUser}/>
                </div>

                {!editProfile ? (
                    <div>
                        <div className="profile-user-data-container">
                            <label className="profile-data-label">{username}</label>
                            <label className="profile-data-label">{name} {surname}</label>
                            <label className="profile-data-label">{email}</label>
                            {phoneNumber ? (
                                <label className="profile-data-label">{phoneNumber}</label>
                            ) : <label className="profile-data-label">Sin número de teléfono</label>}
                            <label className="profile-data-label">{role}</label>
                            {licenseNumber ? (
                                <label className="profile-data-label">Licencia {licenseNumber}</label>
                            ) : (
                                <label className="profile-data-label">Sin licencia asignada</label>
                            )}
                            {licenseToken && role === "Admin" ? (
                                <label className="profile-data-label">Token: {licenseToken}</label>
                            ) : null}
                            
                            <button
                                className="profile-button-to-edit-profile"
                                onClick={handleEditProfile}
                            >
                                    Editar usuario
                            </button>
                            
                            <button
                                className="profile-button-to-edit-profile"
                                onClick={toChangePassword}
                            >
                                    Cambiar contraseña
                            </button>
                            {role === "Admin" ? null : (
                                <button
                                    className="profile-button-to-edit-profile"
                                    onClick={modifyLicense}
                                >
                                        Editar licencia
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="profile-editable-container">
                        <div className="profile-user-data-container">
                            <div className="profile-single-data-container">
                                <label>Nombre de usuario</label>
                                <input
                                    className="profile-input-edit"
                                    type="text"
                                    placeholder="Nombre de usuario"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)} />
                                {errors.userNameError ? <label>{errors.userNameError}</label> : null}
                            </div>

                            <div className="profile-single-data-container">
                                <label>Nombre</label>
                                <input
                                    className="profile-input-edit"
                                    type="text"
                                    placeholder="Nombre"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                                {errors.nameError ? <label>{errors.nameError}</label> : null}
                            </div>

                            <div className="profile-single-data-container">
                                <label>Apellidos</label>
                                <input
                                    className="profile-input-edit"
                                    type="text"
                                    placeholder="Apellidos"
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)} />
                                {errors.surnameError ? <label>{errors.surnameError}</label> : null}
                            </div>

                            <div className="profile-single-data-container">
                                <label>Email</label>
                                <input
                                    className="profile-input-edit"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                {errors.emailError ? <label>{errors.emailError}</label> : null}
                            </div>

                            <div className="profile-single-data-container">
                                <label>Teléfono</label>
                                <input
                                    className="profile-input-edit"
                                    type="text"
                                    placeholder="Teléfono"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)} />
                                {errors.phoneNumberError ? <label>{errors.phoneNumberError}</label> : null}
                            </div>
                        </div>
                        <div className="profile-save-buttons-container">
                            <button
                                className="profile-button-edit-profile"
                                onClick={updateUser}
                            >
                                Guardar
                            </button>
                            <button
                                className="profile-button-cancel-profile"
                                onClick={handleEditProfile}
                            >
                                Descartar
                            </button>
                        </div>
                    </div>
                )}

            </body>

        </div>
    )
}

export default Profile